<template>
  <Page color="info" title="Dispensationen">
    <v-toolbar flat class="mb-4">
      <TermPicker
        outlined
        text
        v-model="term"
        :range="[-3, 1]"
        v-if="!$route.path.includes('pending')"
      />

      <v-spacer />
    </v-toolbar>
    <v-card :loading="loading">
      <v-system-bar>Dispensationen</v-system-bar>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-content>
            <v-list-item-subtitle>
              {{ item.physicalEducation ? "Sportdispensation" : "Dispensation"
              }}<span v-if="item.description">
                «{{ item.description }}»</span
              ></v-list-item-subtitle
            >
            <v-list-item-title v-if="item.startDate == item.endDate">
              <DateSpan :value="[item.startDate, item.endDate]" long />,
              <span v-if="item.endTime && item.startTime">
                {{ item.startTime }} – {{ item.endTime }}</span
              >
              <span v-else>ganztägig</span>
            </v-list-item-title>
            <v-list-item-title v-else-if="item.endTime && item.startTime">
              <DateValue :value="item.startDate" long />, {{ item.startTime }} –
              <DateValue :value="item.endDate" long />,
              {{ item.endTime }}
            </v-list-item-title>
            <v-list-item-title v-else>
              <DateSpan :value="[item.startDate, item.endDate]" long />,
              ganztägig
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense v-if="items.length == 0"
          ><v-list-item-subtitle>keine</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import DateValue from "common/components/DateValue.vue";
import DateSpan from "common/components/DateSpan.vue";
import TermPicker from "common/components/TermPicker.vue";

export default defineComponent({
  name: "Dispensations",
  components: { DateValue, DateSpan, TermPicker },
  data() {
    return {
      loading: false,
      items: [],
      term: null,
    };
  },
  watch: {
    term() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.term) return;
      this.loading = true;
      const data = await this.apiList({
        resource: "absence/dispensation",
        query: `student=${this.$_profilePerson.id}&startDate=${this.term.startDate}&endDate=${this.term.endDate}`,
      });
      this.items = data.sort((a, b) =>
        b.startDate.localeCompare(a.startDate, "de-CH")
      );
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
